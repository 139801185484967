<template>
  <v-container class="mt-3 mb-16">
    <div class="banner-with-text">
      <label for="">อัตราค่าจัดส่ง</label>
      <img
        src="@/assets/images/banner/delivery-info-banner.png"
        class="hidden-sm-and-down"
      >
    </div>
    <div class="info-paragraph">
      <label
        class="info-header"
      >กรณีการจัดส่งสินค้าผ่านขนส่งเอกชน </label>
      <label
        class="info-body"
      >ทางร้านจัดส่งสินค้าผ่านขนส่งเอกชนวันจันทร์-วันเสาร์ เวลา 13.00น.</label>
      <label
        class="info-body"
      >หากท่านสั่งสินค้าหลังจากเวลาดังกล่าว การสั่งซื้อของท่านจะถูกส่งออกผ่านขนส่งเอกชนในวันทำการถัดไป</label>
      <label
        class="info-body"
      >มีค่าส่งดังนี้</label>
      <label
        class="info-body"
      >ยอด 0-7000 บาท = ค่าส่ง 50 บาท</label>
      <label
        class="info-body"
      >ยอด 7000 บาทขึ้นไป = ฟรีค่าส่ง</label>
    </div>
    <div class="info-paragraph">
      <label
        class="info-header"
      >สินค้าที่มีค่าส่งพิเศษคิดแยกต่างหาก (คิดค่าจัดส่งพิเศษกรณีการจัดส่งสินค้าผ่านขนส่งเอกชนเท่านั้น) </label>
      <label
        class="info-body"
      >ของเหลว เช่น ยาน้ำ, ยาฉีด, แอลกอฮอล, น้ำเกลือ, น้ำกลั่น, น้ำตาเทียม เป็นต้น</label>
      <label
        class="info-body"
      >สินค้าที่มีน้ำหนักเยอะ	 เช่น เกลือแร่, อาหารทางการแพทย์ เป็นต้น</label>
      <label
        class="info-body"
      >สินค้ามูลค่าต่ำที่กินพื้นที่เยอะ เช่น Syringe, ขวดเปล่า, เข็ม, ถุงมือ, สำลี, ก๊อซ เป็นต้น</label>
      <label
        class="info-body"
      >สินค้าขนาดใหญ่ เช่น	เตียง, รถเข็น, เสาน้ำเกลือ, เครื่องผลิต Oxygen, ถัง Oxygen เป็นต้น</label>
      <label
        class="info-body"
      >*สินค้าแต่ละประเภท มีการคิดค่าจัดส่งพิเศษแตกต่างกันไปตามความเหมาะสมของ ขนาด และน้ำหนัก ในอนาคตอาจมีการปรับค่าส่งสินค้าพิเศษลดลง</label>
    </div>
    <div class="info-paragraph">
      <label
        class="info-header"
      >กรณีรับสินค้าที่สาขา รับสินค้าผ่านรถขนส่งของทางร้าน และรับสินค้าภายในเขตเทศบาลเมืองชัยภูมิ</label>
      <label
        class="info-body"
      >ฟรีค่าจัดส่งทุกยอดการสั่งซื้อ ในอนาคตอาจมีการขยายเส้นทางการเดินรถของทางร้าน เพื่อบริการลูกค้าได้อย่างทั่วถึงมากขึ้น และลดภาระค่าขนส่งของลูกค้าค่ะ</label>
      <label
        class="info-body"
      >*ค่าส่งสินค้าไม่นำมาคำนวณการได้รับ Capsule</label>
    </div>
  </v-container>
</template>
<script>
export default {
  name: 'DeliveryInfo',
};
</script>
<style scoped>
.banner-with-text {
  height: 120px;
  background-color: #f6efff;
  width: 100%;
  display: flex;
}

.banner-with-text > * {
  display: inline-flex;
}

.banner-with-text > label {
  font-family: Kanit;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.banner-with-text > img {
  margin-left: auto;
}

.info-paragraph {
    margin: 12px 0px;
    display: flex;
    flex-direction: column;
}

.info-paragraph > .info-header {
  font-family: Kanit;
  font-size: 16px;
  font-weight: 600;
  color: #751fde;
  margin: 12px 0px !important;
}

.info-paragraph > .info-body {
    font-family: Sarabun;
    font-size: 16px;
    font-weight: 600;
}
</style>